import { DivisionType, MaterialisedPropertyData, SaleTitle, TitleInclusionState } from '@property-folders/contract';
import { anyTitlePortions, determineTitleInclusionState } from './validation/expected-evaluator';
import { Predicate } from '../../predicate';
import { canonicalisers, friendlyDateFormatterFull } from '../../util/formatting';
import { contractSettlementDefaultEnables } from '../../data-and-text/constants';

export function proposedPrefixTextGen(depositChoice: DivisionType | undefined, proposedLots: string | undefined, saleTitles: SaleTitle[] | undefined) {
  if (!(depositChoice && proposedLots)) {
    return '';
  }
  const { titleInclusionState, explicit } = determineTitleInclusionState(saleTitles || []);
  return explicit && titleInclusionState === TitleInclusionState.portion && proposedLots
    ? depositChoice === DivisionType.Community
      ? `Proposed Lot(s) ${proposedLots}`
      : `Proposed Allotment(s) ${proposedLots}`
    : '';
}

/**
 * Notably different from other headline generators as it does not consider transaction 'headline'
 */
export function summariseAddressesOrTitles(transRoot?: Pick<MaterialisedPropertyData, 'saleAddrs' | 'saleTitles' | 'titleDivision'>, narrowMode = false) {
  if (!transRoot) {
    return '';
  }
  const proposedLots = proposedPrefixTextGen(
    transRoot.titleDivision?.depositChoice,
    transRoot.titleDivision?.proposedLots,
    transRoot.saleTitles
  );
  if (
    !(Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr)
    && !(Array.isArray(transRoot.saleTitles) && transRoot.saleTitles.length > 0 && transRoot.saleTitles[0].title)
  ) {
    // If there are absolutely no property details, just forget about it
    return proposedLots;
  }

  if (
    !(Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr)
    && Array.isArray(transRoot.saleTitles) && transRoot.saleTitles.length > 0 && transRoot.saleTitles[0].title
  ) {
    return [
      proposedLots,
      transRoot.saleTitles.map(t => (anyTitlePortions([t]) ? 'Portion of ' : 'Whole of ') + t.title).join(' and ')
    ].filter(Predicate.isTruthy).join(', ');
  }

  if (Array.isArray(transRoot.saleAddrs) && transRoot.saleAddrs.length > 0 && transRoot.saleAddrs[0].streetAddr) {
    return [
      proposedLots,
      (narrowMode
        ? transRoot.saleAddrs.map(a => a.streetAddr)
        : transRoot.saleAddrs.map(a => [a.streetAddr, a.subStateAndPost].join(', '))
      ).join(' and ')
    ].filter(Predicate.isTruthy).join(', ');
  }

  // Fall over condition. The above is written like guard statements, but TS still doesn't
  // like it
  return proposedLots;
}

export function summariseContractSettlement(property?: Pick<MaterialisedPropertyData, 'contractSettlement'>) {
  const settlement = property?.contractSettlement;
  const optionVisibility = settlement?.enabledModes && Object.values(settlement?.enabledModes??{}).some(a=>a) ? settlement.enabledModes : contractSettlementDefaultEnables;
  const applicableSettlementSegments = [
    (optionVisibility?.date) && property?.contractSettlement?.onDate && property.contractSettlement.date ? friendlyDateFormatterFull(new Date(property.contractSettlement.date)) : null,
    (optionVisibility?.contract) && property?.contractSettlement?.onContract && property.contractSettlement?.onContractDays ? `${property.contractSettlement?.onContractDays} days after the Contract Date` : null,
    (optionVisibility?.condition) && property?.contractSettlement?.onCondition && property.contractSettlement?.onConditionDays ? `${canonicalisers.days(property.contractSettlement?.onConditionDays).canonical} Business Days after the date of all conditions being satisfied or waived` : null,
    (optionVisibility?.other) &&property?.contractSettlement?.other && property.contractSettlement?.otherText ? property.contractSettlement?.otherText : null
  ].filter(Predicate.isNotNullish);

  return applicableSettlementSegments.length > 1
    ? `On the later of:\n${applicableSettlementSegments.reduce((acc, cv, idx, arr)=>`${acc}${cv}${idx >= arr.length-1?'.':'; or\n'}`, '')}`
    : applicableSettlementSegments.length === 1
      ? applicableSettlementSegments[0] // Having a period after this looks a bit out of place on a summary sheet when it isn't multiple parts
      : '';
}
