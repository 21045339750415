import {
  AgencySalesperson,
  SessionInfo,
  SignerProxyType,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType,
  SigningPartyVerificationType
} from '@property-folders/contract';
import { insertPossessiveSuffix } from '@property-folders/common/util/formatting';
import { Predicate } from '@property-folders/common/predicate';
import {
  EntitySettingsSigningOptions, GreatformsSigningDefaults
} from '@property-folders/contract/yjs-schema/entity-settings';

export const SigningPartyTypeOptions = {
  [SigningPartyType.SignOnline]: 'Email link',
  [SigningPartyType.SignInPerson]: 'On salesperson\'s screen',
  [SigningPartyType.SignWet]: 'On paper',
  [SigningPartyType.SignOnlineSms]: 'SMS link'
};

export const SignerProxyAuthorityOptions = {
  [SignerProxyType.Self]: '',
  [SignerProxyType.Auctioneer]: 'Auctioneer',
  [SignerProxyType.Salesperson]: 'Salesperson',
  [SignerProxyType.Proxy]: 'Proxy',
  [SignerProxyType.TeamMember]: 'Signing Agent'
};

export function replaceSignInPersonSalespersonToken(label: string, candidateName: string, myAgentId?: number, candidateAgentId?: number | string) {
  return label.replace(
    ' salesperson\'s ',
    myAgentId === candidateAgentId
      ? ' my '
      : candidateName === '' ? ' ' : ` ${insertPossessiveSuffix(candidateName)} `
  );
}

export function buildFilteredSigningPartyTypeOptions({
  sessionInfo,
  signingParty,
  defaultHost,
  salespersons,
  partyData,
  noUndefinedSalesperson,
  signingOptions,
  disallowPaper,
  extraSettings
}: {
  sessionInfo: SessionInfo | undefined,
  signingParty: SigningParty | undefined,
  defaultHost: { id: number, name: string } | undefined,
  salespersons: AgencySalesperson[] | undefined,
  // from main transaction data
  partyData: { linkedSalespersonId?: number | string, name?: string, isNotSalesperson?: boolean } | undefined,
  noUndefinedSalesperson?: boolean,
  signingOptions: EntitySettingsSigningOptions | undefined,
  disallowPaper?: boolean
  extraSettings?: GreatformsSigningDefaults
}) {
  return Object.entries(SigningPartyTypeOptions).flatMap<{ name: string, label: string, type: number }>(([rawName, rawLabel]) => {
    const type = parseInt(rawName) as SigningPartyType;
    const myAgentId = sessionInfo && 'agentId' in sessionInfo ? sessionInfo.agentId : undefined;
    const signingPartySourceType = signingParty?.source.overrideType || signingParty?.source.type;

    switch (type) {
      case SigningPartyType.SignInPerson: {
        if ([SignerProxyType.TeamMember].includes(signingParty?.proxyAuthority ?? SignerProxyType.Self) && signingParty?.proxyName && signingParty?.proxyLinkedId) {
          return [{
            name: `${rawName}_${signingParty.proxyLinkedId}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, signingParty.proxyName || 'salesperson', myAgentId, signingParty.proxyLinkedId),
            type
          }];
        }
        if (signingPartySourceType === SigningPartySourceType.Salesperson) {
          return [{
            name: `${rawName}_`,
            label: replaceSignInPersonSalespersonToken(rawLabel, partyData?.name || '', myAgentId, partyData?.linkedSalespersonId),
            type
          }];
        }
        if ([SignerProxyType.Salesperson].includes(signingParty?.proxyAuthority ?? SignerProxyType.Self) && signingParty?.proxyName && signingParty.proxyLinkedId) {
          return [{
            name: `${rawName}_${signingParty.proxyLinkedId}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, signingParty.proxyName || 'salesperson', myAgentId, signingParty.proxyLinkedId),
            type
          }];
        }
        const rList = [];
        // Initiator is not a listed Agent
        const salespersonIdList = (salespersons || []).map(sp => sp.linkedSalespersonId);
        if ((!noUndefinedSalesperson || defaultHost?.id) && !salespersonIdList.includes(defaultHost?.id)) {
          rList.push({
            name: `${rawName}_${defaultHost?.id}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, defaultHost?.name || 'user', myAgentId, defaultHost?.id),
            type
          });
        }
        rList.push(...(salespersons || []).map(sp => {
          return {
            name: `${rawName}_${sp.linkedSalespersonId}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, sp?.name || 'agent', myAgentId, sp?.linkedSalespersonId),
            type
          };
        }));
        return rList;
      }
      case SigningPartyType.SignOnline:
      case SigningPartyType.SignOnlineSms:
        if (!partyData?.isNotSalesperson && partyData?.linkedSalespersonId === myAgentId) {
          return undefined;
        }

        return [{ name: `${rawName}_`, label: rawLabel, type }];

      case SigningPartyType.SignWet:
        if (!signingOptions?.allowPaper) return undefined;
        if (disallowPaper) return undefined;
        return [{ name: `${rawName}_`, label: rawLabel, type }];
      default:
        return [{ name: `${rawName}_`, label: rawLabel, type }];
    }
  })
    .filter(entry => {
      if (!entry) {
        return false;
      }

      if (!extraSettings) {
        return true;
      }

      if (extraSettings.enable) {
        return extraSettings.enable.includes(entry.type);
      }

      if (extraSettings.disable) {
        return !extraSettings.disable.includes(entry.type);
      }

      return true;
    })
    .filter(Predicate.isNotNullish);

}

export const SigningPartyVerificationTypeOptions = {
  [SigningPartyVerificationType.Sms]: 'SMS'
};

export interface IPartyDetailPaths {
  /**
   * name, email, phone can vary based on party source
   */
  data: {
    base: string;
    name: string;
    email: string;
    phone: string;
    partyType?: string;
    authority?: string;
    // update: MaybeUpdateFn<MaterialisedPropertyData>
  },
  meta: {
    base: string;
  },
  dataIsMeta?: boolean // Only used by CustomiseVerification at the time of writing this comment
}
