import { FormContextType } from '@property-folders/common/types/FormContextType';
import { propertyFolder, purchaserPortalRoot } from '@property-folders/contract/yjs-schema/model/field';
import { AwarenessData, FormCode } from '@property-folders/contract';
import { saleContractFormRules } from '@property-folders/contract/yjs-schema/model/form';
import { omit } from 'lodash';
import { ValidationDefnType } from '@property-folders/contract/yjs-schema/model';

export const purchaserPortalFormNames = {
  registration: 'newPurchaser',
  offer: 'makeOffer'
};

export const registrationFormContext: FormContextType = {
  formName: purchaserPortalFormNames.registration,
  formId: '',
  // note: all the isPrimaryContact are to try and restrict this validation to just the primary purchaser
  formRules: {
    purchasers: {
      _children: {
        addressSingleLine: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] }
        },
        authority: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] }
        },
        personName1: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] },
              { evaluationFunction: 'isRepresentative', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        phone1: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] },
              { evaluationFunction: 'primarySubcontactIsFirstPerson', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        fullLegalName: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] }
        },
        partyType: {
          _requiredIf: { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] }
        },
        personName2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] },
              { evaluationFunction: 'isRepresentative', field: ['..'] },
              { evaluationFunction: 'isDualPartyType', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        phone2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isPrimaryContact', field: ['..', '..', '..', 'primaryPurchaser'] },
              { evaluationFunction: 'primarySubcontactIsSecondPerson', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        }
      },
      _minimum: 1,
      _required: true
    }
  },
  transactionRules: purchaserPortalRoot,
  fieldGroups: {
    'purchasers.[].partyType': 'partyResetActivity',
    'purchasers.[].authority': 'partyResetActivity',
    'purchasers.[].inTrust': 'partyResetActivity',
    'purchasers.[].registeredOnTitle': 'partyResetActivity'
  },
  metaRules: {
    _type: 'Map'
  }
};

const saleContractFormRulesSubset: ValidationDefnType = {
  ...omit(saleContractFormRules,
    '_atLeastDefns',
    'vendors',
    'vendorGst',
    'contractPrice.inputTaxed',
    'contractPrice.gstIncluded',
    'contractPrice.marginApplied',
    'cgtEnable',
    // don't want to validate particulars section.
    'exclusions',
    'chattels',
    'titleDivision'
  ),
  contractOther: {
    enable: { _prefill: { value: false }, _required: true },
    otherText: {
      _requiredIf: { expectedValue: true, field: ['..', 'enable'] }
    }
  },
  contractSpecial: {
    financeRequired: {
      _prefill: { value: false },
      _required: true
    },
    financeDeadlineTime: {
      // Not required because we don't show this field anymore
      _prefill: {
        value: '17:00'
      }
    },
    financeDeadline: {
      _requiredIf: { expectedValue: true, field: ['..', 'financeRequired'] }
    },
    financeAmount: {
      _requiredIf: { expectedValue: true, field: ['..', 'financeRequired'] }
    },
    financeInterestRate: {
      _requiredIf: { expectedValue: true, field: ['..', 'financeRequired'] }
    },
    purchaserSaleRequired: {
      _prefill: { value: false },
      _required: true
    },
    saleSettleAlsoRequired: {
      _requiredIf: { expectedValue: true, field: ['..', 'purchaserSaleRequired'] }
    },
    salePropertySingleLine: {
      _requiredIf: { expectedValue: true, field: ['..', 'purchaserSaleRequired'] }
    },
    purchaserSaleContractDeadline: {
      _requiredIf: { expectedValue: true, field: ['..', 'purchaserSaleRequired'] }
    },
    purchaserSaleSettlementDeadline: {
      _requiredIf: { expectedValue: true, field: ['..', 'purchaserSaleRequired'] }
    },
    purchaserSaleMinimumPrice: {
      _requiredIf: {
        expectations: [
          { expectedValue: true, field: ['..', 'purchaserSaleRequired'] },
          { notExpectedValue: true, field: ['..', 'saleSettleAlsoRequired'] }
        ], modeOrTrueAndFalse: false
      }
    }
  },
  _complexValidators: ['settlementDatesGreaterEqThan']
};
if (!saleContractFormRulesSubset.contractPrice) saleContractFormRulesSubset.contractPrice = {};
saleContractFormRulesSubset.contractPrice.deposit = { _required: true };
if (!saleContractFormRulesSubset.contractSettlement) saleContractFormRulesSubset.contractSettlement = {};
const dateRules: ValidationDefnType = {
  ...(saleContractFormRulesSubset.contractSettlement.date ?? {}),
  _customValidation: [{
    type: 'notBeforeDate',
    params: { otherDatePath: ['..', '..', 'contractSpecial', 'purchaserSaleSettlementDeadline'] },
    enableIf: {
      expectations: [
        { expectedValue: true, field: ['..', '..', 'contractSpecial', 'purchaserSaleRequired'] },
        { expectedValue: true, field: ['..', 'onDate'] }
      ],
      modeOrTrueAndFalse: false
    }
  }]
};
saleContractFormRulesSubset.contractSettlement.date = dateRules;

export const offerContractFormContext: FormContextType = {
  formName: FormCode.RSC_ContractOfSale,
  formId: '',
  formRules: saleContractFormRulesSubset,
  transactionRules: propertyFolder,
  fieldGroups: {
    'contractPrice.purchasePrice': 'purchasePriceGst',
    'contractPrice.purchaseGst': 'purchasePriceGst'
  },
  metaRules: {
    _type: 'Map'
  }
};

export const offerFormContext: FormContextType = {
  formName: FormCode.OfferToPurchase,
  formId: '',
  formRules: saleContractFormRulesSubset,
  transactionRules: propertyFolder,
  fieldGroups: {},
  metaRules: {
    _type: 'Map'
  }
};

export const reviewPrimaryPurchaserFormContext: FormContextType = {
  formName: purchaserPortalFormNames.offer,
  formId: '',
  formRules: registrationFormContext.formRules,
  transactionRules: propertyFolder,
  fieldGroups: registrationFormContext.fieldGroups,
  metaRules: {
    _type: 'Map'
  }
};

export const reviewOtherPurchasersFormContext: FormContextType = {
  formName: purchaserPortalFormNames.offer,
  formId: '',
  formRules: {
    purchasers: {
      _children: {
        addressSingleLine: {
          _required: true
        },
        authority: {
          _required: true
        },
        personName1: {
          _requiredIf: { evaluationFunction: 'isRepresentative', field: ['..'] }
        },
        phone1: {
          _required: true
        },
        fullLegalName: {
          _required: true
        },
        partyType: {
          _required: true
        },
        personName2: {
          _requiredIf: {
            expectations: [
              { evaluationFunction: 'isRepresentative', field: ['..'] },
              { evaluationFunction: 'isDualPartyType', field: ['..'] }
            ],
            modeOrTrueAndFalse: false
          }
        },
        phone2: {
          _requiredIf: { evaluationFunction: 'isDualPartyType', field: ['..'] }
        }
      },
      _minimum: 1,
      _required: true
    }
  },
  transactionRules: propertyFolder,
  fieldGroups: {
    'purchasers.[].partyType': 'partyResetActivity',
    'purchasers.[].authority': 'partyResetActivity',
    'purchasers.[].inTrust': 'partyResetActivity',
    'purchasers.[].registeredOnTitle': 'partyResetActivity',
    'purchasers.[].addressSingleLine': 'purchaserAddrSameAsPrimary',
    'purchasers.[].addrSameAsPrimary': 'purchaserAddrSameAsPrimary'
  },
  metaRules: {
    _type: 'Map'
  }
};

export const initialPresence: AwarenessData = {
  location: {
    path: ''
  }
};
