import { SubscriptionFormCode } from './types';
import { ExtraFormCode, FormCode } from '@property-folders/contract';
import { FormDescriptorRecord, FormState } from '../yjs-schema/property/types';

export const SubscriptionFormTypes: Record<SubscriptionFormCode, FormDescriptorRecord> = {
  [SubscriptionFormCode.SAF001V2_Form1]: {
    label: 'FORM 1 - Vendor\'s Statement',
    shortLabel: 'Form 1',
    description: 'FORM 1 - Vendor\'s Statement',
    orderable: true,
    debug: false,
    formFamily: FormCode.Form1,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    parties: [{ type: 'vendor', mode: 'primary-only' }, { type: 'agent' }],
    authRepParties: [{ type: 'agent' }, { type: 'vendor' }],
    disableCounterpartSigning: true,
    subscription: {
      signing: {
        placementStrategies: {
          agent: {
            type: 'fill-contiguous-space',
            expandDown: 10,
            marginRight: 43,
            marginLeft: 55,
            signedText: {
              size: 10,
              width: 40
            }
          },
          vendor: {
            type: 'fill-contiguous-space',
            expandDown: 10,
            marginRight: 43,
            marginLeft: 55,
            signedText: {
              size: 10,
              width: 40
            },
            initialDivisionDirection: 'rows-first'
          }
        },
        serveFields: [{
          field: 'PURCHASER_NAME',
          location: { page: 0, left: 143, top: 516, width: 391, height: 28 },
          font: { size: 10 }
        },{
          field: 'PURCHASER_ADDRESS',
          location: { page: 0, left: 143, top: 544, width: 391, height: 28 },
          font: { size: 10 }
        },{
          field: 'CONTRACT_DATE',
          location: { page: 0, left: 290, top: 713, width: 242, height: 15 },
          font: { size: 10 }
        }]
      }
    },
    documentExpiryPaths: {},
    documentExpiryPathsDefaults: {},
    serveToPurchaser: true,
    disableWetSigning: true,
    archiveSiblingTypesOnCreate: [FormCode.Form1, SubscriptionFormCode.SAF001V2_Form1, ExtraFormCode.Form1Upload]
  },
  [SubscriptionFormCode.SACS015_LicenceToOccupy]: {
    label: 'Licence to Occupy',
    description: 'Licence to Occupy',
    formFamily: FormCode.LicenceToOccupy,
    suggestion: { [FormCode.RSC_ContractOfSale]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    parties: [{ type: 'vendor' }, { type: 'purchaser' }],
    subscription: {
      signing: {
        placementStrategies: {
          vendor: {
            type: 'fill-contiguous-space',
            expandDown: 20,
            marginRight: 43,
            marginLeft: 55
          },
          purchaser: {
            type: 'fill-contiguous-space',
            expandDown: 20,
            marginRight: 43,
            marginLeft: 55
          }
        }
      }
    },
    documentExpiryPaths: {},
    documentExpiryPathsDefaults: {}
  },
  [SubscriptionFormCode.SAR008_VendorQuestionnaire]: {
    label: 'Vendor Questionnaire',
    description: 'Vendor Questionnaire',
    formFamily: FormCode.VendorQuestionnaire,
    suggestion: { [FormCode.RSAA_SalesAgencyAgreement]: { [FormState.SIGNED]: true } },
    renderOpts: {
      suggestionActions: ['create', 'view']
    },
    parties: [{ type: 'vendor' }],
    subscription: { },
    documentExpiryPaths: {},
    documentExpiryPathsDefaults: {}
  }
};
