const MILLIMETERS_IN_INCH = 25.4;
const POINTS_IN_INCH = 72;

export function mmToPoints(mm: number) {
  const inches = mm / MILLIMETERS_IN_INCH;
  return inches * POINTS_IN_INCH;
}

export function pointsToMm(pt: number) {
  const inches = pt/POINTS_IN_INCH;
  return inches * MILLIMETERS_IN_INCH;
}

export function pointsToPixels(pt: number, pixelsPerInch: number) {
  // px / ppi = pt / points_per_inch;
  return (pt / POINTS_IN_INCH) * pixelsPerInch;
}

export function dimensionsToPixelsPerInch(width: number, height: number, mode = 'A4'): number {
  // use min of width/height to account for landscape dimensions.
  return Math.ceil(Math.min(width, height) / A4_WIDTH_INCHES);
}

export const MARGIN_LEFT = mmToPoints(15);
export const MARGIN_TOP = mmToPoints(35);
export const MARGIN_RIGHT = mmToPoints(15);
export const MARGIN_BOTTOM = mmToPoints(25.4);
export const MARGIN_ABOVE_HEADER = mmToPoints(10);
export const MARGIN_BELOW_FOOTER = mmToPoints(4);

export const MARGIN_ITEM_LEFT = 16;

export type MarginTuple = [number, number, number, number];
export const FIRST_PAGE_MARGIN: MarginTuple = [mmToPoints(15), mmToPoints(35), mmToPoints(15), mmToPoints(18)];
export const horizontalOverrideFirstPage = (hMargin: number) => [hMargin, FIRST_PAGE_MARGIN[1], hMargin, FIRST_PAGE_MARGIN[3]] as MarginTuple;
export const SUBSEQUENT_PAGE_MARGIN: MarginTuple = [mmToPoints(15), mmToPoints(26), mmToPoints(15), mmToPoints(18)];

export const CONTRACT_HORIZONTAL_MARGIN = mmToPoints(7);

export const A4_WIDTH = Math.round((210*100*72)/25.4)/100;
export const A4_WIDTH_MM = 210;
export const A4_WIDTH_INCHES = A4_WIDTH_MM/25.4;
export const A4_HEIGHT = Math.round((297*100*72)/25.4)/100;
export const A4_HEIGHT_INCHES = 11.7;
export const A4_ASPECT_RATIO_WH = 210/297;
// Should approximate sqrt(2) and be the same for all A series pages
export const A4_ASPECT_RATIO_HW = 297/210;
